import React from 'react';

export default function App() {
  return (
    <div id="page-top">
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
          <a className="navbar-brand js-scroll-trigger" href="#page-top">
            <span className="d-block d-lg-none">Kiran Wadkar</span>
            {/* <span className="d-none d-lg-block"><img className="img-fluid img-profile rounded-circle mx-auto mb-2" src="assets/img/profile.png" alt="..." /></span> */}
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav">
              <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#about">About</a></li>
              <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#experience">Experience</a></li>
              <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#education">Education</a></li>
              <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#skills">Skills</a></li>
              <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#projects">Hobby Projects</a></li>
              <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#awards">Awards</a></li>
            </ul>
          </div>
        </nav>

        <div className="container-fluid p-0">
            <section className="resume-section" id="about">
                <div className="resume-section-content">
                    <h1 className="mb-0">
                        Kiran
                        <span className="text-primary"> Wadkar</span>
                    </h1>
                    <div className="subheading mb-5">
                        Irving, TX 75063 · (940) 279-9905 ·
                        <a href="mailto:name@email.com"> kiranwadkar35@email.com</a>
                    </div>
                    <p className="lead mb-5">I am experienced data analyst skilled in Power BI, python, matplotlib, and pandas.</p>
                    <div className="social-icons">
                        <a className="social-icon" href="https://www.linkedin.com/in/kiranwadkar-a5816b175/"><i className="fab fa-linkedin-in"></i></a>
                        <a className="social-icon" href="https://github.com/kwadkar35"><i className="fab fa-github"></i></a>
                        <a className="social-icon" href="https://github.com/kwadkar35"><i className="fab fa-youtube"></i></a>
                    </div>
                </div>
            </section>
            <hr className="m-0" />


            <section className="resume-section" id="experience">
                <div className="resume-section-content">
                    <h2 className="mb-5">Experience</h2>
                    <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                        <div className="flex-grow-1">
                            <h3 className="mb-0">Senior Power BI Developer</h3>
                            <div className="subheading mb-3">Intelitec Solutions</div>
                            <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation X is on the runway heading towards a streamlined cloud solution. User generated content in real-time will have multiple touchpoints for offshoring.</p>
                        </div>
                        <div className="flex-shrink-0"><span className="text-primary">March 2013 - Present</span></div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                        <div className="flex-grow-1">
                            <h3 className="mb-0">Web Developer</h3>
                            <div className="subheading mb-3">Intelitec Solutions</div>
                            <p>Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.</p>
                        </div>
                        <div className="flex-shrink-0"><span className="text-primary">December 2011 - March 2013</span></div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                        <div className="flex-grow-1">
                            <h3 className="mb-0">Junior Web Designer</h3>
                            <div className="subheading mb-3">Shout! Media Productions</div>
                            <p>Podcasting operational change management inside of workflows to establish a framework. Taking seamless key performance indicators offline to maximise the long tail. Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive convergence on cross-platform integration.</p>
                        </div>
                        <div className="flex-shrink-0"><span className="text-primary">July 2010 - December 2011</span></div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <div className="flex-grow-1">
                            <h3 className="mb-0">Web Design Intern</h3>
                            <div className="subheading mb-3">Shout! Media Productions</div>
                            <p>Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. Dramatically visualize customer directed convergence without revolutionary ROI.</p>
                        </div>
                        <div className="flex-shrink-0"><span className="text-primary">September 2008 - June 2010</span></div>
                    </div>
                </div>
            </section>
            <hr className="m-0" />


            <section className="resume-section" id="education">
                <div className="resume-section-content">
                    <h2 className="mb-5">Education</h2>
                    <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                        <div className="flex-grow-1">
                            <h3 className="mb-0">University of Colorado Boulder</h3>
                            <div className="subheading mb-3">Bachelor of Science</div>
                            <div>Computer Science - Web Development Track</div>
                            <p>GPA: 3.23</p>
                        </div>
                        <div className="flex-shrink-0"><span className="text-primary">August 2006 - May 2010</span></div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <div className="flex-grow-1">
                            <h3 className="mb-0">James Buchanan High School</h3>
                            <div className="subheading mb-3">Technology Magnet Program</div>
                            <p>GPA: 3.56</p>
                        </div>
                        <div className="flex-shrink-0"><span className="text-primary">August 2002 - May 2006</span></div>
                    </div>
                </div>
            </section>
            <hr className="m-0" />


            <section className="resume-section" id="skills">
                <div className="resume-section-content">
                    <h2 className="mb-5">Skills</h2>
                    <div className="subheading mb-3">Programming Languages & Tools</div>
                    <ul className="list-inline dev-icons">
                      <li className="list-inline-item">
                          <i className="fas fa-database fa-sm"></i> SQL&nbsp;&nbsp;&nbsp;
                      </li>
                      <li className="list-inline-item">
                          <i className="fab fa-microsoft fa-sm"></i> Power BI&nbsp;&nbsp;&nbsp;
                      </li>
                      <li className="list-inline-item">
                          <i className="fab fa-python fa-sm"></i> Python&nbsp;&nbsp;&nbsp;
                      </li>
                      <li className="list-inline-item">
                          <i className="fas fa-chart-line fa-sm"></i> Matplotlib&nbsp;&nbsp;&nbsp;
                      </li>
                      <li className="list-inline-item">
                          <i className="fas fa-cube fa-sm"></i> NumPy&nbsp;&nbsp;&nbsp;
                      </li>
                      <li className="list-inline-item">
                          <i className="fas fa-table fa-sm"></i> Pandas
                      </li>
                  </ul>

                </div>
            </section>
            <hr className="m-0" />
  

            <section className="resume-section" id="projects">
            <div className="resume-section-content">
                <h2 className="mb-5">Hobby Projects</h2>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card mb-4">
                            <div className="card-body">
                                <h5 className="card-title">Customer Segmentation Dashboard</h5>
                                <p className="card-text">Customer Segmentation Dashboard in PowerBI using Python, Pandas, and Matplotlib.</p>
                                <a href="https://github.com/kwadkar35" className="btn btn-primary">Click Here</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card mb-4">
                            <div className="card-body">
                                <h5 className="card-title">Happiness Index Dashboard</h5>
                                <p className="card-text">Happiness Index Dashboard in PowerBI using Python, Pandas, and Matplotlib.</p>
                                <a href="https://github.com/kwadkar35" className="btn btn-primary">Click Here</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card mb-4">
                            <div className="card-body">
                                <h5 className="card-title">Supply Chain Dashboard</h5>
                                <p className="card-text">Supply Chain Dashboard in PowerBI using Python, Pandas, and Matplotlib.</p>
                                <a href="https://github.com/kwadkar35" className="btn btn-primary">Click Here</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card mb-4">
                            <div className="card-body">
                                <h5 className="card-title">A/B Testing Analysis</h5>
                                <p className="card-text">A/B Testing Analysis.</p>
                                <a href="https://github.com/kwadkar35" className="btn btn-primary">Click Here</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card mb-4">
                            <div className="card-body">
                                <h5 className="card-title">A/B Testing Analysis</h5>
                                <p className="card-text">A/B Testing Analysis.</p>
                                <a href="https://github.com/kwadkar35" className="btn btn-primary">Click Here</a>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </section>
            <hr className="m-0" />


            <section className="resume-section" id="awards">
                <div className="resume-section-content">
                    <h2 className="mb-5">Awards & Certifications</h2>
                    <ul className="fa-ul mb-0">
                        <li>
                            <span className="fa-li"><i className="fas fa-trophy text-warning"></i></span>
                            Promoted to Senior Consultant position for good and consistent performance in Infosys.
                        </li>
                        <li>
                            <span className="fa-li"><i className="fas fa-trophy text-warning"></i></span>
                            Received Quarterly Award in Q1 2022 in Infosys for the outstanding performance.
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    </div>
  );
}